<template>
    <div class="points" :class="{'checkMobile':checkMobile}" v-if="points && pointsShow.length">
        <div class="points_title">
            <p>Meus indicadores</p>
            <div class="pagination">
                <div v-for="i in totalPages" :key="i" :class="{active: i === currentPage}" class="spot"
                     @click="currentPage = i">
                    <p>{{ i + 1 }}</p>
                </div>
            </div>
        </div>
        <div class="points_list">
            <div class="point_container" v-for="point in pointsShow" :key="point.name">
                <div class="point_title">
                    <p>{{ point.name }}</p>
                </div>
                <div class="point_amount" :class="{negative: point.amount < 0}">
                    <p>
                        {{ point.amount }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'Points',
    data() {
        return {
            points: null,
            currentPage: 0,
        }
    },
    computed: {
        ...mapGetters(['token',"checkMobile"]),
        maxPerPage() {
            if (this.checkMobile)
                return 1;
            return 3;
        },
        pointsShow() {
            return this.points ? this.points.slice(this.currentPage * this.maxPerPage, (this.currentPage + 1) * this.maxPerPage) : [];
        },
        totalPages() {
            const maxPagesShow = 6;
            let total = this.points ? Math.ceil(this.points.length / this.maxPerPage) : 0;
            let pages =Array.from({length: total}, (v, k) => k);
            //only have spots
            if (total <= maxPagesShow) {
                return pages;
            }
            //have more spots than maxPagesShow
            let start = this.currentPage - Math.ceil(maxPagesShow / 2);
            let end = this.currentPage + Math.floor(maxPagesShow / 2);
            if (start < 0) {
                start = 0;
                end = maxPagesShow;
            }
            if (end > total) {
                end = total;
                start = total - maxPagesShow;
            }
            return pages.slice(start, end);
        }
    },
    components: {},
    methods: {
        ...mapActions([]),
        getPoints() {

            this.$app.wait(true);
            axios.get(this.$url_api + "api/user/points", {headers: this.$app.headers})
                .then((response) => {
                    this.getPointsResponse(response);
                })
                .catch((error) => {
                    this.getPointsError(error.response);
                })
                .finally(() => {
                    this.$app.wait(false);
                });

        },
        getPointsResponse(obj) {
            // console.log('OBJ', obj);
            // console.log('OBJ DATA', obj.data);
            this.points = obj.data.data.points;
            /*  // with delay
              this.$nextTick(() => {
                  this.points.forEach((point, index) => {
                      setTimeout(() => {
                          this.animateCount(this.$refs.pointElements[index], point.amount);
                      }, index * 400);  // delay before start the next item
                  });
              });*/
        },
        getPointsError(obj) {
            console.log('getPointsError => ', obj);
        },
        /**
         * Animates the counting from 0 to a specified end value for a given HTML element.
         * @param {HTMLElement} element - The HTML element to animate.
         * @param {number} endValue - The target value to count up to.
         */
        animateCount(element, endValue) {
            const duration = 2000;                                 // Duration of the animation in milliseconds
            const frameDuration = 1000 / 60;                            // Duration of each frame in milliseconds (assuming 60 frames per second)
            const totalFrames = Math.round(duration / frameDuration); // Total number of frames needed for the animation
            const easeOutQuad = t => t * (2 - t);                     // Easing function to smoothen the animation (Quadratic Out)
            let frame = 0;                                    // Counter to keep track of the current frame
            const countFrom = 0;                                    // Initial count value
            const countTo = endValue;                             // Target count value

            const counter = () => {                                                             // Function to update the count on each frame
                frame++;
                const progress = easeOutQuad(frame / totalFrames);                         // Calculate the progress of the animation based on the current frame
                const currentCount = Math.round(countFrom + (countTo - countFrom) * progress); // Calculate the current count value based on the progress
                if (element) {
                    element.innerText = currentCount;
                }               // Update the inner text of the element with the current count value
                if (frame < totalFrames) {
                    requestAnimationFrame(counter);
                }                 // Continue the animation if there are more frames remaining
            };

            requestAnimationFrame(counter);                             // Start the animation by requesting the first frame
        },
    },
    mounted() {
        this.$app.wait(true);
        this.getPoints();
    }
}
</script>

<style scoped lang="scss">
.points {
    &.checkMobile {
        .points_list {
            flex-direction: column;
            .point_container {
                width: 100%;
                margin: 10px 0;
            }
        }
    }
    .points_title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        p {
            font-size: 20px;
            font-family: Hanken Grotesk;
            font-weight: 700;
            color: #706F6F;
        }

        .pagination {
            display: flex;
            justify-content: center;

            .spot {
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: rgba(177, 177, 177, 1);
                cursor: pointer;
                transition: all 0.3s;
                margin: 0 5px;
                &.active {
                    background: var(--buttons_color);
                }
                p {
                    display: none;
                    font-size: 12px;
                    font-family: Hanken Grotesk;
                    font-weight: 700;
                    color: #706F6F;
                }
            }
        }
    }

    .points_list {
        display: flex;
        width: 100%;
        align-items: center;
        //overflow-x: auto;
        margin-bottom: 60px;
        padding: 16px 0;

        .point_container {
            display: flex;
            flex-direction: column;
            border-radius: 30px;
            width: calc(33% - 20px);
            padding: 30px;
            margin: 1px 10px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
            align-self: stretch;

            .point_title {
                margin-top: 12px;
                width: 90%;
                // height: 50px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 20px;

                p {
                    font-size: 18px;
                    color: #706F6F;
                    font-family: Open Sans;
                    font-weight: 400;
                    text-align: left;
                    text-transform: capitalize;
                    line-height: 22px;
                }
            }

            .point_amount {
                margin-bottom: 20px;
                width: 90%;
                text-align: start;
                margin-top: auto;

                p {
                    font-size: 42px;
                    color: var(--buttons_color);
                    font-weight: 700;
                }
            }

        }

        .no_data {
            width: 100%;
            height: 400px;
            color: #706F6F;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid #E1E1E1;
            text-align: center;
            background: #FFF;

            svg {
                margin-bottom: 40px;
                fill: #E1E1E1;
            }
        }
    }
}

/* media queries (mobile last) */
@media only screen and (max-width: 1470px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 480px) {

}
</style>